import { useState/*, useEffect, useCallback*/ } from 'react';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from 'sweetalert2'

//FECHAS
const validarMail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(email);
}

const hoy = new Date();
const cumpleanos = new Date("1987-12-29");
let edad = hoy.getFullYear() - cumpleanos.getFullYear();
const m = hoy.getMonth() - cumpleanos.getMonth();
if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
}

const inicio = new Date("2013-01-01");
let experiencia = hoy.getFullYear() - inicio.getFullYear();
const m2 = hoy.getMonth() - inicio.getMonth();
if (m2 < 0 || (m2 === 0 && hoy.getDate() < inicio.getDate())) {
    experiencia--;
}
const anos_experiencia = experiencia+4;
//FIN FECHAS

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function App() {
  const [modal, setModal] = useState({
    ver: false,
    titulo: "",
  })

  const [idioma, setIdioma] = useState("es");

  const [c_nombre, set_c_nombre] = useState("");
  const [c_correo, set_c_correo] = useState("");
  const [c_mensaje, set_c_mensaje] = useState("");

  const [categoria, set_categoria] = useState(0);

  const limpiar = () => {
    setModal({
      ver: false,
      titulo: "",
    })

    set_c_nombre("")
    set_c_correo("")
    set_c_mensaje("")
    set_categoria(0)
  }

  const Titulo = () => {
    return(
      <div className="titulo">
        <div className="logo">
          <div className="nombre">Fernando</div>
          <div className="apellido_p">Contreras</div>
          <div className="apellido_m">de la Garza</div>
          <div className={idioma==='es' ? "sub_titulo" : "sub_titulo_ingles"}>{idioma==='es' ? 'Diseño y programación' : 'Design and programming'}</div>
        </div>
      </div>
    )
  }

  const Conoceme = () => {
    return(
      <div>
        {/*<h7>{window.nombre}</h7>*/}
        <div className="espacio_input"/>
        <img src="img/yo.jpg" className="img_libro" title={window.nombre} alt={window.nombre}/>
        <div className="texto_libro">
          {
            idioma==='es' ? 
              'Soy egresado de la carrera Ingeniería en Gestión Empresarial, actualmente laboro en la Secretaría de Desarrollo Económico en el área de sistemas desarrollando sitios web, sistemas y aplicaciones móviles. Me gusta desarrollar ideas y llevar los conceptos a la realidad, a mi forma de trabajar, haciendo cosas increíbles que les guste a las personas.'
            :
              'I am a graduate of Business Management Engineering, I currently work at the Secretaría de Desarrollo Económico in the systems area developing websites, systems and mobile applications. I like to develop ideas and bring concepts to reality, to my way of working, doing incredible things that people like.'
          }
          <br/><br/>
          {
            idioma==='es' ? 
              `Hace ${experiencia} años estoy trabajando de desarrollador web y he aprendido bastante, considero que las TIC'S integran más a nuestro contexto de la vida de una manera más sencilla e intuitiva.`
            :
              `I have been working as a web developer for ${experiencia} years and I have learned a lot. I consider that ICT integrates more into our context of life in a simpler and more intuitive way.`
          }
        </div>
        <div id="cl"/>
        <div className="espacio_input"/>
        <h8>{idioma==='es' ? 'Información personal' : 'Personal information'}</h8>
        <div className="flex_informacion">
          <div><b>{idioma==='es' ? 'Nacionalidad' : 'Nationality'}:</b><br/>{idioma==='es' ? 'Mexicana' : 'Mexicana'}</div>
          <div><b>{idioma==='es' ? 'Ciudad' : 'City'}:</b><br/>CDMX</div>
          <div><b>{idioma==='es' ? 'Edad' : 'Age'}:</b><br/>{ edad } {idioma==='es' ? 'años' : 'years'}</div>
          <div><b>{idioma==='es' ? 'Carrera' : 'Career'}:</b><br/>{idioma==='es' ? 'Gestión Empresarial' : 'Business Management'}</div>
          <div><b>{idioma==='es' ? 'Experiencia' : 'Experience'}:</b><br/>{ anos_experiencia } {idioma==='es' ? 'años' : 'years'}</div>
        </div>
        {
          window._my_educacion.length>0 ?
            <div>
              <div className="espacio_input"/>
              <h8>{idioma==='es' ? 'Formación académica' : 'Academic training'}</h8>
              <ul className="lista">
                {
                  window._my_educacion.map((item, i) => 
                    <li key={i}>
                      {
                        item.link ?
                          <a href={item.link} target="new"><b>{idioma==='es' ? item.nivel : item.nivelIN}</b> {item.escuela}</a>
                        :
                          <div><b>{item.nivel}</b> {item.escuela}</div>
                      }
                    </li>
                  )
                }
              </ul>
            </div>
          :
            null
        }
        {
          window._my_experiencia.length>0 ?
            <div>
              <h8>{idioma==='es' ? 'Experiencia laboral' : 'Work experience'}</h8>
              <ul className="lista">
                {
                  window._my_experiencia.map((item, i) => 
                    <li key={i}>
                      {
                        item.link ?
                          <a href={item.link} target="new">{idioma==='es' ? item.desc : item.descIN}</a>
                        :
                          <div>{idioma==='es' ? item.desc : item.descIN}</div>
                      }
                    </li>
                  )
                }
              </ul>
            </div>
          :
            null
        }
      </div>
    )
  }

  const Habilidades = () => {
    return(
      <div>
        {
          window._my_tecnicas.length>0 ?
            <div>
              <h8>{idioma==='es' ? 'Habilidades Técnicas' : 'Technical skills'}</h8>
              <div className="personales">
                <table>
                  {
                    window._my_tecnicas.filter(p => window.screen.width>800 ? p.posicion===1 : p.posicion).map((item, i) => 
                      <tr key={i}>
                        <td className="t_titulo"><b>{item.nombre}</b></td>
                        <td className="t_barra">
                          <div className="barra" title={item.porcentaje+"%"}>
                            <div className="color" style={{width:item.porcentaje+"%"}}/>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </table>
                {
                  window.screen.width>800 && window._my_tecnicas.filter(p => p.posicion===2).length>0 ?
                    <table>
                      {
                        window._my_tecnicas.filter(p => p.posicion===2).map((item, i) => 
                          <tr key={i}>
                            <td><b>{item.nombre}</b></td>
                            <td>
                              <div className="barra" title={item.porcentaje+"%"}>
                                <div className="color" style={{width:item.porcentaje+"%"}}/>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    </table>
                  :
                    null
                }
              </div>
            </div>
          :
            null
        }
        {
          window._my_personales.length>0 ?
            <div>
              <h8>{idioma==='es' ? 'Habilidades Personales' : 'Personal skills'}</h8>
              <div className="personales">
                <table>
                  {
                    window._my_personales.filter(p => window.screen.width>800 ? p.posicion===1 : p.posicion).map((item, i) => 
                      <tr key={i}>
                        <td className="t_titulo_dos"><b>{idioma==='es' ? item.nombre : item.nombreIN}</b></td>
                        <td>{item.porcentaje===5 ? "✦✦✦✦✦" : item.porcentaje===4 ? "✦✦✦✦✧" : item.porcentaje===3 ? "✦✦✦✧✧" : item.porcentaje===2 ? "✦✦✧✧✧" : item.porcentaje===1 ? "✦✧✧✧✧" : "✧✧✧✧✧"}</td>
                      </tr>
                    )
                  }
                </table>
                {
                  window.screen.width>800 && window._my_personales.filter(p => p.posicion===2).length>0 ?
                    <table>
                      {
                        window._my_personales.filter(p => p.posicion===2).map((item, i) => 
                          <tr key={i}>
                            <td><b>{idioma==='es' ? item.nombre : item.nombreIN}</b></td>
                            <td>{item.porcentaje===5 ? "✦✦✦✦✦" : item.porcentaje===4 ? "✦✦✦✦✧" : item.porcentaje===3 ? "✦✦✦✧✧" : item.porcentaje===2 ? "✦✦✧✧✧" : item.porcentaje===1 ? "✦✧✧✧✧" : "✧✧✧✧✧"}</td>
                          </tr>
                        )
                      }
                    </table>
                  :
                    null
                }
              </div>
            </div>
          :
            null
        }
        {
          window._my_hobbies.length>0 ?
            <div>
              <h8>Hobbies</h8>
              <div className="hobbies">
                {
                  window._my_hobbies.map((item, i) => 
                    <img src={item.img} title={idioma==='es' ? item.nombre : item.nombreIN} alt={item.nombre} key={i}/>
                  )
                }
              </div>
            </div>
          :
            null
        }
        <div className="boton_cv"><a href={window._my_cv} target="new">{idioma==='es' ? 'Descargar CV' : 'Download CV'}</a></div>
      </div>
    )
  }

  const Servicios = () => {
    return(
      <div>
        {
          window._my_servicios.length>0 ?
            <Accordion>
              {
                window._my_servicios.map((item, i) =>
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{idioma==='es' ? item.nombre : item.nombreIN}</Accordion.Header>
                    <Accordion.Body>
                      {item.descripcion ? <div dangerouslySetInnerHTML={{ __html: idioma==='es' ? item.descripcion : item.descripcionIN }} className="nota_texto"/> : null}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              }
            </Accordion>
          :
            null
        }
      </div>
    )
  }

  const Portafolio = () => {
    return(
      <div>
        {
          categoria>0 ?
            <div>
              <div className="flex_t_portafolio">
                {
                  window._my_portafolio.map((item, i) =>
                    <div key={i} className="grises" id={categoria===item.id ? 'activo' : null} onClick={() => {
                      window.H5_loading.show();
                      if(categoria===item.id){
                        set_categoria(0)
                      }else{
                        set_categoria(item.id)
                      }
                      window.H5_loading.hide();
                    }}>
                      {idioma==='es' ? item.categoria : item.categoriaIN}
                    </div>
                  )
                }
              </div>
              {
                window._my_portafolio.filter(p => p.id===categoria).length>0 ?
                  window._my_portafolio.filter(p => p.id===categoria)[0].proyecto.length>0 ?
                    <div className="galeria">
                      {
                        window._my_portafolio.filter(p => p.id===categoria)[0].proyecto.map((item, i) =>
                          <div key={i} className="my_galeria" onClick={() => {
                            //setModal(true)
                            //setIndex(item.id)
                            if(item.link){
                              window.open(item.link)
                            }
                          }}>
                            <img src={item.foto} title={item.titulo} alt={item.titulo}/>
                            <div className="hover_galeria">
                              <div className="cajas_galeria">
                                {item.titulo}
                                {/*
                                <div className="linea_galeria"/>
                                <h13>{item.fecha}</h13>
                                */}
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  :
                    null
                :
                  null
              }
            </div>
          :
            <div className="flex_portafolio">
              {
                window._my_portafolio.length>0 ?
                  window._my_portafolio.map((item, i) =>
                    <div key={i} className="grises" onClick={() => {
                      window.H5_loading.show();
                      set_categoria(item.id)
                      window.H5_loading.hide();
                    }}>
                      <img src={item.img} title={idioma==='es' ? item.categoria : item.categoriaIN} alt={item.categoria}/>
                      <div>{idioma==='es' ? item.categoria : item.categoriaIN}</div>
                    </div>
                  )
                :
                  idioma==='es' ? 'No hay registros' : 'There are no records'
              }
            </div>
        }
      </div>
    )
  }

  return (
    <div>
      <div className="principal">
        <div className="contenido">
          <div className="max_width padding_width">
            <div className="flex_contenido">
              <div className="sal_titulo"><Titulo /></div>
              <div className="menu">
                <div className="c_menu" onClick={() => {
                  setModal({
                    ver: true,
                    titulo: "Conóceme",
                  })
                }}>
                  {idioma==='es' ? 'Conóceme' : 'Know me'}
                </div>
                <div className="c_espacio"></div>
                <div className="c_menu" onClick={() => {
                  setModal({
                    ver: true,
                    titulo: "Habilidades",
                  })
                }}>
                  {idioma==='es' ? 'Habilidades' : 'Skills'}
                </div>
              </div>
              <div className="quita_titulo"><Titulo /></div>
              <div className="menu sal_espacio">
                <div className="c_menu" onClick={() => {
                  setModal({
                    ver: true,
                    titulo: "Servicios",
                  })
                }}>
                  {idioma==='es' ? 'Servicios' : 'Services'}
                </div>
                <div className="c_espacio"></div>
                <div className="c_menu" onClick={() => {
                  setModal({
                    ver: true,
                    titulo: "Portafolio",
                  })
                }}>
                  {idioma==='es' ? 'Portafolio' : 'Portafolio'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="idioma_flex">
          <div className="de_idioma">
            <img src="https://agc.com.mx/img/idioma_es.svg" className="grises" alt="Español" title="Español" onClick={() => {setIdioma("es")}}/>
            <img src="https://agc.com.mx/img/idioma_en.svg" className="grises" alt="English" title="English" onClick={() => {setIdioma("in")}}/>
          </div>
        </div>
        <div className="foto_posicion">
          <div className="foto"></div>
        </div>
        <div className="pie">
          <div className="max_width padding_pie">
            <div className="flex_pie">
              <div className="pie_nombre">
                <a href={window._my_cv} title="Curriculum Vitae" alt="CV" className="cv_pie">Fernando </a>
                <div>Contreras de la Garza</div>
              </div>
              <div className="redes">
                {
                  window._my_redes.map((item, i) => 
                    <a href={item.link} target="_new" className="de_flex_redes grises" key={i}>
                      <img src={item.imagen} alt={item.nombre} title={item.nombre}/>
                    </a>
                  )
                }
                <div className="de_flex_redes grises sobre" onClick={() => {
                  setModal({
                    ver: true,
                    titulo: "Contacto",
                  })
                }}>
                  <img src="img/r_sobre-01.svg" alt="Contacto" title={idioma==='es' ? 'Contacto' : 'Contact'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modal.ver}
        size="lg"
        centered
        onHide={() => limpiar()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {
              idioma==='es' ?
                modal.titulo
              : modal.titulo==='Conóceme' ?
                'Know me'
              : modal.titulo==='Habilidades' ?
                'Skills'
              : modal.titulo==='Servicios' ?
                'Services'
              : modal.titulo==='Portafolio' ?
                'Portafolio'
              : modal.titulo==='Contacto' ?
                'Contact'
              :
                null
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            modal.titulo==='Conóceme' ?
              <Conoceme />
            : modal.titulo==='Habilidades' ?
              <Habilidades />
            : modal.titulo==='Servicios' ?
              <Servicios />
            : modal.titulo==='Portafolio' ?
              <Portafolio />
            : modal.titulo==='Contacto' ?
              <div className="flex_contacto">
                <div className="informacion_contacto">
                  <h7>{idioma==='es' ? 'Información de contacto' : 'Contact information'}</h7>
                  <a href={window.whats} target="new" className="flex_tel">
                    <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
                    <div>{window.telefono}</div>
                  </a>
                  <a href={`mailto:${window.correo}`} target="new" className="flex_tel">
                    <img src="img/r_sobre-01.svg" title={idioma==='es' ? 'Correo electrónico' : 'Email'} alt="Correo electrónico"/>
                    <div>{window.correo}</div>
                  </a>
                  <a href={window.mapa} target="new" className="flex_tel">
                    <img src="img/ubicacion.svg" title={idioma==='es' ? 'Ubicación' : 'Location'} alt="Ubicación"/>
                    <div>{window.ubicacion}</div>
                  </a>
                  <a href={window.web} target="new" className="flex_tel">
                    <img src="img/www.svg" title="www" alt="www"/>
                    <div>{window.webn}</div>
                  </a>
                  {idioma==='es' ? 'Mis redes sociales:' : 'My social networks:'}
                  <div className="redes redes_dos">
                    {
                      window._my_redes.map((item, i) => 
                        <a href={item.link} target="_new" className="de_flex_redes grises" key={i}>
                          <img src={item.imagen} alt={item.nombre} title={item.nombre}/>
                        </a>
                      )
                    }
                  </div>
                </div>
                <Form className="formulario">
                  <h7>{idioma==='es' ? 'Dejame un mensaje' : 'Send a message'}</h7>
                  <div className="espacio_input"/>
                  <Form.Group>
                    {/*<Form.Label>Nombre:</Form.Label>*/}
                    <Form.Control type="text" placeholder={idioma==='es' ? '*Nombre completo:' : '*Full name:'} value={c_nombre} onChange={(event) => {set_c_nombre(event.target.value)}}/>
                  </Form.Group>
                  <div className="espacio_input"/>
                  <Form.Group>
                    {/*<Form.Label>Correo electrónico:</Form.Label>*/}
                    <Form.Control type="text" placeholder={idioma==='es' ? '*Correo electrónico:' : '*Email:'} value={c_correo} onChange={(event) => {set_c_correo(event.target.value)}}/>
                  </Form.Group>
                  <div className="espacio_input"/>
                  <Form.Group>
                    {/*<Form.Label>Mesnaje:</Form.Label>*/}
                    <Form.Control as="textarea" rows={3} placeholder={idioma==='es' ? '*Mensaje:' : '*Message:'} value={c_mensaje} onChange={(event) => {set_c_mensaje(event.target.value)}}/>
                  </Form.Group>
                  <div className="campos">
                    {idioma==='es' ? 'Campos obligatorios' : 'Required fields'} (*)
                  </div>
                  <div id="cl"></div>
                  <div className="fin_botones">
                    <Button type="submit" variant="success" onClick={(event) => {
                      event.preventDefault()
                      if(!c_nombre || !c_correo || !c_mensaje){
                        if(idioma==='es'){
                          alerta('error','Un campo requerido esta vacío');
                        }else{
                          alerta('error','A required field is empty');
                        }
                      }else if(validarMail(c_correo)===false){
                        if(idioma==='es'){
                          alerta('error', 'Ingrese un correo electrónico valido');
                        }else{
                          alerta('error','Enter a valid email');
                        }
                      }else{
                        window.H5_loading.show();
                        var pasar = new URLSearchParams();

                        pasar.append('c_nombre', c_nombre);
                        pasar.append('c_correo', c_correo);
                        pasar.append('c_mensaje', c_mensaje);
                        
                        Axios.post(window.envia, pasar)
                          .then(response => {
                            if(response.data.elError===1){
                              if(idioma==='es'){
                                alerta('success', ''+response.data.mensaje);
                              }else{
                                alerta('success',''+response.data.mensajeIN);
                              }
                              limpiar()
                            }else{
                              if(idioma==='es'){
                                alerta('error', ''+response.data.mensaje);
                              }else{
                                alerta('error',''+response.data.mensajeIN);
                              }
                            }
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .then(function() {
                            window.H5_loading.hide();
                          })
                      }
                    }}>
                      {idioma==='es' ? 'Enviar' : 'Send'}
                    </Button>
                  </div>
                </Form>
              </div>
            :
              null
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
